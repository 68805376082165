body {
  --font-family: 'Roboto', sans-serif;

  --font-primary: #1f2223;
  --font-secondary: #717c80;
  --font-white: #ffffff;
  --font-placeholder: #85878a;
  --font-description: #676769;

  --primary: #2c60a2;
  --primary-2: var(--primary);
  --primary-3: #007ec2;
  --secondary: #00a490;
  --secondary-2: #24354b;
  --error-color: #f44336;
  --blue: #2ea5e5;
  --green: #00a490;
  --red: #dc313b;
  --orange: #ac6422;

  --background-primary: #f6f8f9;
  --background-secondary: #ffffff;
  --background-header: #ffffff;
  --background-grey: #f1f2f3;
  --background-grey-2: #e6e8e9;
  --background-grey-3: #b8b8b8;
  --background-grey-4: #fcfcfc;
  --background-grey-5: #d4d8d9;
  --background-grey-6: #f6f6f6;
  --background-alert: #ff9999;
  --background-status: #f3f3f3;
  --background-order-info: #ffb800;

  --border-color-primary: #ced4da;
  --border-color-primary-focus: var(--primary);
  --border-color-secondary-1: #e8e8e8;
  --border-color-secondary-2: #f6f8f9;
  --border-color-secondary-3: #e0e0e0;
  --border-color-secondary-4: #ededed;
  --group-border-color: #ddd;

  --border-radius-card: calc(var(--border-radius-primary) + 4px);
  --border-radius-primary: 12px;
  --border-radius-secondary: calc(var(--border-radius-primary) - 4px);
  --border-radius-input: calc(var(--border-radius-primary) - 9px);

  --icon-fill: #a1a1a1;

  --primary-scroll-track: #e9e9e9;
  --primary-scroll-thumb: var(--primary);

  --secondary-scroll-track: #e9e9e9;
  --secondary-scroll-thumb: #c8c9ca;

  --product-image-mix-blend-mode: multiply;

  --dialog-bottom-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    var(--background-secondary) 45.01%,
    var(--background-secondary)
  );
}
